<template>
  <section id="not-sold-products">
    <b-card>
      <b-row>
        <b-col xl="3" md="3">
          <v-select v-model="selectedPlatform" label="title" placeholder="Platform" :options="allPlatforms"
            @input="changeSelectedPlatform" />
        </b-col>
        <b-col xl="6" md="6">
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showNotSoldProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Not sold products in the last 5 years
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-not-sold-products" />
          </h4>
          <b-popover target="popover-not-sold-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportNotSoldProductsStart" spinner-variant="primary" variant="transparent" blur="3px"
              rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportNotSoldProducts" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="notSoldProductsTable.currentPage"
          :items="notSoldProductsTable.items" :fields="notSoldProductsTable.fields"
          :sort-by.sync="notSoldProductsTable.sortBy" :sort-desc.sync="notSoldProductsTable.sortDesc"
          :sort-direction="notSoldProductsTable.sortDirection" :filter="notSoldProductsTable.filter"
          :filter-included-fields="notSoldProductsTable.filterOn" @sort-changed="sortChangedNotSoldProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="notSoldProductsTable.currentPage" :total-rows="notSoldProductsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleNotSoldProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showNotSoldOnStockProducts" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            On stock not sold products in the last 3 years
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-not-sold-stock-products" />
          </h4>
          <b-popover target="popover-not-sold-stock-products" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportNotSoldOnStockProductsStart" spinner-variant="primary" variant="transparent"
              blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportNotSoldOnStockProducts" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="notSoldOnStockProductsTable.currentPage" :items="notSoldOnStockProductsTable.items"
          :fields="notSoldOnStockProductsTable.fields" :sort-by.sync="notSoldOnStockProductsTable.sortBy"
          :sort-desc.sync="notSoldOnStockProductsTable.sortDesc"
          :sort-direction="notSoldOnStockProductsTable.sortDirection" :filter="notSoldOnStockProductsTable.filter"
          :filter-included-fields="notSoldOnStockProductsTable.filterOn"
          @sort-changed="sortChangedNotSoldOnStockProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="notSoldOnStockProductsTable.currentPage"
              :total-rows="notSoldOnStockProductsTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0" @change="handleNotSoldOnStockProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showProductsDropSales" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Products with the highest drop of sales
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-products-highest-drop-sales" />
          </h4>
          <b-popover target="popover-products-highest-drop-sales" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportProductsDropSalesStart" spinner-variant="primary" variant="transparent"
              blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportProductsDropSales" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="productsDropSalesTable.currentPage"
          :items="productsDropSalesTable.items" :fields="productsDropSalesTable.fields"
          :sort-by.sync="productsDropSalesTable.sortBy" :sort-desc.sync="productsDropSalesTable.sortDesc"
          :sort-direction="productsDropSalesTable.sortDirection" :filter="productsDropSalesTable.filter"
          :filter-included-fields="productsDropSalesTable.filterOn" @sort-changed="sortChangedProductsDropSales" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="productsDropSalesTable.currentPage" :total-rows="productsDropSalesTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleProductsDropSalesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BButton,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BCard,
    BOverlay,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showNotSoldProducts: true,
      showNotSoldOnStockProducts: true,
      showProductsDropSales: true,
      showExportNotSoldProductsStart: false,
      showExportNotSoldOnStockProductsStart: false,
      showExportProductsDropSalesStart: false,
      selectedPlatform: '',
      allPlatforms: [],
      queryParamsNotSoldProducts: {},
      queryParamsNotSoldOnStockProducts: {},
      queryParamsProductsDropSales: {},
      notSoldProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sales_price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'purchase_price',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sales_price',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'overall_stock_qty', label: 'overall stock qty', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'platform', label: 'platform', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      notSoldOnStockProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sales_price',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'purchase_price',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sales_price',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'overall_stock_qty', label: 'overall stock qty', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          {
            key: 'last_order_date',
            label: 'last order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'platform', label: 'platform', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      productsDropSalesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'qty_difference',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'total_qty_sold', label: 'total qty sold', sortable: true },
          { key: 'qty_sold_prev_mtd_year', label: 'qty sold prev. year MTD', sortable: true },
          { key: 'qty_sold_current_year', label: 'qty sold curr. year', sortable: true },
          { key: 'qty_difference', label: 'qty difference', sortable: true },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          {
            key: 'turnover_prev_mtd_year',
            label: 'turnover prev. year MTD',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'turnover_current_year',
            label: 'turnover curr. year',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'order_count_prev_mtd_year', label: 'order count prev. year MTD', sortable: true },
          { key: 'order_count_current_year', label: 'order count curr. year', sortable: true },
          { key: 'platform', label: 'platform', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getPlatforms();
      await this.getNotSoldProducts();
      await this.getNotSoldOnStockProducts();
      await this.getProductsDropSales();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getPlatforms() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/platforms/`, {});
        this.allPlatforms = response.data.results.map(item => item.name);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getNotSoldProducts() {
      this.showNotSoldProducts = true;
      // 5 years ago
      this.queryParamsNotSoldProducts.to_date = new Date(new Date().setFullYear(new Date().getFullYear() - 5)).toISOString().split('T')[0];
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-not-sold/`, this.queryParamsNotSoldProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.notSoldProductsTable.items = results;
          this.notSoldProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.notSoldProductsTable.items = [];
          this.notSoldProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotSoldProducts = false;
      }
    },
    async getNotSoldOnStockProducts() {
      this.showNotSoldOnStockProducts = true;
      // 3 years ago
      this.queryParamsNotSoldOnStockProducts.to_date = new Date(new Date().setFullYear(new Date().getFullYear() - 3)).toISOString().split('T')[0];
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-on-stock-not-sold/`, this.queryParamsNotSoldOnStockProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.notSoldOnStockProductsTable.items = results;
          this.notSoldOnStockProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.notSoldOnStockProductsTable.items = [];
          this.notSoldOnStockProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showNotSoldOnStockProducts = false;
      }
    },
    async getProductsDropSales() {
      this.showProductsDropSales = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/products-drop-sales/`, this.queryParamsProductsDropSales);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.productsDropSalesTable.items = results;
          this.productsDropSalesTable.totalRows = results[0].count * 2;
        }
        else {
          this.productsDropSalesTable.items = [];
          this.productsDropSalesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showProductsDropSales = false;
      }
    },
    async sortChangedNotSoldProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsNotSoldProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsNotSoldProducts.ordering = value.sortBy;
      }
      await this.getNotSoldProducts();
    },
    async sortChangedNotSoldOnStockProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsNotSoldOnStockProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsNotSoldOnStockProducts.ordering = value.sortBy;
      }
      await this.getNotSoldOnStockProducts();
    },
    async sortChangedProductsDropSales(value) {
      if (value.sortDesc === true) {
        this.queryParamsProductsDropSales.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsProductsDropSales.ordering = value.sortBy;
      }
      await this.getProductsDropSales();
    },
    async handleNotSoldProductsTablePageChange(value) {
      this.queryParamsNotSoldProducts.page = value;
      await this.getNotSoldProducts();
    },
    async handleNotSoldOnStockProductsTablePageChange(value) {
      this.queryParamsNotSoldOnStockProducts.page = value;
      await this.getNotSoldOnStockProducts();
    },
    async handleProductsDropSalesTablePageChange(value) {
      this.queryParamsProductsDropSales.page = value;
      await this.getProductsDropSales();
    },
    async changeSelectedPlatform() {
      this.queryParamsNotSoldProducts.platform = this.selectedPlatform;
      this.queryParamsNotSoldOnStockProducts.platform = this.selectedPlatform;
      this.queryParamsProductsDropSales.platform = this.selectedPlatform;
      await this.getNotSoldProducts();
      await this.getNotSoldOnStockProducts();
      await this.getProductsDropSales();
    },
    async exportNotSoldProducts() {
      try {
        this.showExportNotSoldProductsStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-not-sold/`, this.queryParamsNotSoldProducts);
        const exportedData = response.data.results;
        this.showExportNotSoldProductsStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'NotSoldProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportNotSoldOnStockProducts() {
      try {
        this.showExportNotSoldOnStockProductsStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-on-stock-not-sold/`, this.queryParamsNotSoldOnStockProducts);
        const exportedData = response.data.results;
        this.showExportNotSoldOnStockProductsStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'NotSoldOnStockProducts.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportProductsDropSales() {
      try {
        this.showExportProductsDropSalesStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-products-drop-sales/`, this.queryParamsProductsDropSales);
        const exportedData = response.data.results;
        this.showExportProductsDropSalesStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'ProductsDropSales.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>